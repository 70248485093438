import React, { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Instafeed from "instafeed.js";
import { useNavigate } from "react-router-dom";

const Instagramfeed = () => {
  const [images, setImages] = useState([]);
  const carouselRef = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    const feed = new Instafeed({
      accessToken: "IGQWRPbTByRmw3LXdmdUU4OFF2b0hTME9FVUtFS0dnbU4xNVhTUHR2aS1EM09OQkFYTTFScmJyV20tUEZAOeTRwalk2NTJpM0wyQkVXdkxSWU5RNGYxTnZAuVXpNM0FmWVNOaERwcVJBdHFDaTkwRUpnOU5MNE5JRmcZD",
      limit: 10,
      target: 'instafeed', // Specify the target element ID
      template: '<a href="{{link}}" target="_blank"><img src="{{image}}" alt="{{caption}}" class="instafeedcard" /></a>',
      after: function () {
        const fetchedImages = Array.from(document.querySelectorAll(".instafeedcard")).map((img) => ({
          imgSrc: img.src,
        }));
        setImages(fetchedImages);
      },
    });

    feed.run();
  }, []);

  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1024: { items: 3 },
  };

  const slidePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const slideNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const handleImageError = (event) => {
    event.target.src = "https://hatimi.s3.amazonaws.com/frontendpropertyImages/Untitled-1+1.jpg";
  };

  const handleNavInsta = () => {
    window.location.href = 'https://www.instagram.com/hatimiretreats/';
  };

  return (
    <div className="container">
      <div className="insta text-white align-items-center pb-3">
        <div className="px-5 mx-5 mobileresponsive">
          <p className="">SOCIAL MEDIA</p>
        </div>
        <div className="d-flex justify-content-start align-items-start">
          <img
            src="/assets/bg/line.svg"
            className="linehome mx-3 mt-4 pt-3"
            alt="image"
          />
          <h1 className="mobileresponsive ">Instagram Feed</h1>
        </div>
      </div>
      <div id="instafeed" style={{ display: 'none' }}></div>
      <div className="mx-5 px-5 mobileresponsive other-room-carousel-wrapper">
        <AliceCarousel
          responsive={responsive}
          autoPlayInterval={3000}
          autoPlay={true}
          infinite={true}
          disableButtonsControls={true}
          disableDotsControls={true}
          buttonsDisabled={true} // Disable default buttons
          ref={carouselRef}
        >
          {images.map((image, index) => (
            <div key={index} className="col cursor-pointer" onClick={handleNavInsta}>
              <img
                   src={image.imgSrc}
                   onError={handleImageError}
                   className="rounded img-fluid instafeedcard"
                   alt={`Instagram post ${index + 1}`}
                  style={{
                      
                      objectFit: "cover",
                      transition: 'height 0.3s ease, width 0.3s ease'
                  }}
                 
              />
            </div>
          ))}
        </AliceCarousel>
        <div className="d-flex justify-content-between align-items-center">
          <button onClick={slidePrev} className="arrow-btn prev m-0">
            <FiChevronLeft className="fs-1" />
          </button>
          <button onClick={slideNext} className="arrow-btn next m-0">
            <FiChevronRight className="fs-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Instagramfeed;
