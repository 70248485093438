import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HashLoader } from "react-spinners";
import {
  setLocation,
  setLoading,
  fetchProperties,
} from "../redux/Property/propertySlice";
import Cookies from 'js-cookie';
import axios from "axios";
import SearchBar from "./HomeContent/SearchBar";
import PropertyCard from "./Cards/PropertyCard";
import About_Properties from "./Properties_Content/About_Properties";
import Attractions from "./Properties_Content/Attractions";
import {setSelectedRoomUid} from "../redux/room/roomSlice"

const Properties = () => {
  const amenityIcons = {
    // Your amenity icons mapping...
  };
  const dispatch = useDispatch();
  const { properties, location, loading, error } = useSelector(
    (state) => state.property
  );

  const filterData = properties?.data?.filter((obj) => obj.property_city === location);
 
  console.log(filterData,"filtered data");
  

  useEffect(() => {
    const destinationData = JSON.parse(Cookies.get("searchData"));
    dispatch(setLocation(destinationData?.destination?.value || ""));
  }, [dispatch]);



  useEffect(() => {
    // Dispatch the fetchProperties action with the current location
    dispatch(fetchProperties());
  }, [dispatch]);

  const updateSearch = ({ destination }) => {

    if(location !== destination.value){
      dispatch(setSelectedRoomUid({}));
    }
    dispatch(setLocation(destination.value)); // Dispatch the action to update location
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
  };

  useEffect(() => {
    // Trigger scroll to top when the component mounts
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
        <HashLoader color={"#B08E54"} loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    // Handle error UI
    return <div>Error loading properties: {error}</div>;
  }

  // Rest of the component remains the same...

  return (
    <div className="pt-2">
      {/* Your existing JSX code for SearchBar and other components... */}

      <div className=" container mobilepaddingmargin">
        <SearchBar
          className="bookings"
          style={{ position: "relative", top: 0 }}
          onButtonClick={updateSearch}
          buttonLabel="Update Search"
          dropdown="property"
        />
      </div>

      <div className="d-flex justify-content-start align-items-start container text-white pt-4 pb-0">
        <img
          src="/assets/bg/line.svg"
          className="linehome mx-3 mt-4 pt-3 "
          alt="image"
        />
        <h1 className="">Properties in {location}</h1>{" "}
        {/* Changed property_city to location */}
      </div>
      <div className="container bootdey">
        {filterData?.length === 0 ? (
          <h2 className="px-5 mx-5 text-white mt-5 mobileresponsive">
            No properties available in {location}
          </h2>
        ) : (
          filterData?.map((property) => (
            <PropertyCard
              key={property._id}
              image={property.property_images[0]}
              property_name={property.property_name}
              property_city={property.property_city}
              property_type={property.property_type}
              max_capacity={`Max capacity of ${property.max_capacity}`}
              property_rating={property.property_rating}
              total_rooms={property.total_rooms}
              property_size={property.property_size}
              tags={property.tags}
              indoor_amenities={property.indoor_amenities}
              amenityIcons={amenityIcons}
              propertyId={property.property_uid}
            />
          ))
        )}
      </div>
      {filterData?.map((property, index) => (
        <About_Properties
          key={index} // Provide a unique key for each component
          location={location}
          property_name={property.property_name}
          property_description={property.property_description}
          property_images={property.property_images}
          propertyId={property.property_uid}
        />
      ))}
      {/* Attractions */}
      {/* <div className="container">
        <Attractions />
      </div> */}
    </div>
  );
};

export default Properties;
