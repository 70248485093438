import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie

// Create the async thunk for decrypting data
export const decryptData = createAsyncThunk(
  'auth/decryptData',
  async (dt, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/auth/its_login`, {
        decryptedString: dt,
      });

      
      // Store the _id in cookies with a 2-hour expiration
      const userId = res.data.data._id || null;
      const token = res.data.accesstoken || null;
      
      Cookies.set('userId', userId, { expires: 2 / 24 });
      Cookies.set('token', token, { expires: 2 / 24 });

      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to decrypt data');
    }
  }
);

// Create the async thunk for fetching customer details
export const fetchCustomerDetails = createAsyncThunk(
  'auth/fetchCustomerDetails',
  async (_, { rejectWithValue }) => {
    try {
      // Retrieve token and user ID directly from Cookies
      const token = Cookies.get('token');
      const userId = Cookies.get('userId');

      if (!token && !userId) {
        throw new Error("No token or user ID available");
      }

      // Make the API request with the token in the headers
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/auth/fetchCustomerDetails`,
        { customer_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch customer details');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    decryptedData: null,
    customerDetails: null,
    status: 'idle',
    customerStatus: 'idle',
    error: null,
    customerError: null,
  },
  reducers: {
    // Other reducers can be added here
  },
  extraReducers: (builder) => {
    builder
      .addCase(decryptData.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(decryptData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.decryptedData = action.payload;
      })
      .addCase(decryptData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchCustomerDetails.pending, (state) => {
        state.customerStatus = 'loading';
        state.customerError = null;
      })
      .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
        state.customerStatus = 'succeeded';
        state.customerDetails = action.payload;
      })
      .addCase(fetchCustomerDetails.rejected, (state, action) => {
        state.customerStatus = 'failed';
        state.customerError = action.payload;
      });
  },
});

export default authSlice.reducer;
