import React from 'react';
import Event_Searchbar from '../components/Event_Content/Event_Searchbar';
import EventCard from '../components/Event_Content/EventCard';

const Events = () => {
  const EventData = [
    {
      id: 1,
      title: "PLAN A MEETING ",
      tagline: "Hatimi Retreats: Your Ideal Corporate Meeting Venue",
      description: "Escape the boring conventional boardrooms and immerse your corporate gathering in the tranquility of Hatimi Retreats. Our unique setting seamlessly blends business with the serenity of nature, fostering collaboration and innovation amidst the calming embrace of the woods. ",
      imageUrl: "/assets/meeting.jpeg",
    },
    {
      id: 2,
      title: "PLAN A WEDDING ",
      tagline: " Hatimi Retreats: The place of Fairytale Weddings",
      description: 'Celebrate an elegant and cost-effective destination wedding at Hatimi Retreats, where our team guarantees a smooth and spectacular experience. Imagine the warmth of our team and the grandeur of our rooms welcoming your guests. ',
      imageUrl: "assets/wedding.jpeg",
    },
    {
      id: 3,
      title: "HOST AN EVENT",
      tagline: "Cherish Memorable Events at Hatimi Retreats. ",
      description: 'Hatimi Retreats offers budget-friendly packages, luxurious accommodations, and an appealing environment. Our vast rooms cater to intimate gatherings and larger celebrations, while our multi-purpose function hall is perfect for grand events. ',
      imageUrl: "assets/event.jpeg",
    },
    // Add more service objects as needed
  ];

  return (
    <div className="container-fluid">
      {/* Text on Image */}
      <div className="events_banner h-100vh">
        {/* Heading and Description */}
        <div className="container text-white">
          <div className="row">
            <div className="d-flex justify-content-center align-items-end pb-5 mb-5">
              <h1 className="">Explore Venues</h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <Event_Searchbar name="event" />
      </div> */}
      {/* Cards */}
      <div className="container mb-5 pb-5">
        {EventData.map((service, index) => (
          <div key={service.id} className={`row text-white mb-5 pb-5 gap-3 ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
            <EventCard
              imageUrl={service.imageUrl}
              title={service.title}
              description={service.description}
              tagline={null}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;
