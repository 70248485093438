// ProductDetail.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { fetchRoomDetails } from "../redux/room/roomDetailSlice"; // Updated import path
import Topimages from "./Product_Detail_content/Topimages";
import Other_Rooms from "./Product_Detail_content/Other_Rooms";
import Product_Description from "./Product_Detail_content/Product_Description";
import "react-toastify/dist/ReactToastify.css";

const ProductDetail = () => {
  const { propertyId, roomId, propertyName, propertyCity, booked } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { rooms, loading } = useSelector((state) => state.roomDetails);
  const otherRooms = state?.availRooms || [];

  useEffect(() => {
    // Dispatch the fetchRoomDetails thunk when the component mounts
    dispatch(fetchRoomDetails({ propertyId, roomId }));
  }, [dispatch, propertyId, roomId]);

  useEffect(() => {
    // Scroll to top on mount
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
        <HashLoader color={"#B08E54"} loading={loading} size={50} />
      </div>
    );
  }

  return (
    <div className="container">
      <Topimages
        key={rooms._id}
        roomImages={rooms.room_images}
        room_name={rooms.room_name}
        room_type={rooms.room_type}
        property_city={propertyCity}
        property_name={propertyName}
        room_images={rooms.room_images[0]}
        room_image={rooms.room_images[1]}
        room_imag={rooms.room_images[2]}
        room_ima={rooms.room_images[3]}
        room_im={rooms.room_images[4]}
      />

      <div className="row text-white mx-5 px-5 mobileresponsive">
        <Product_Description
          room_description={rooms.room_description}
          bed_size={rooms.bed_size}
          room_number={rooms.room_number}
          max_guest_occupancy={rooms.max_guest_occupancy}
          room_size={rooms.room_size}
          amenities={rooms.room_amenities}
          amenityIcons={rooms.amenityIcons}
        />
      </div>
{/* 
      {otherRooms.length === 0 ? (
        <p>No other rooms available.</p>
      ) : (
        <Other_Rooms
          otherRooms={otherRooms.filter((room) => room.room_uid !== roomId)}
          property_name={propertyName}
          property_city={propertyCity}
          is_booked={booked}
        />
      )} */}
    </div>
  );
};

export default ProductDetail;
