import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decryptData, fetchCustomerDetails } from '../redux/Auth/AuthSlice';
import { HashLoader } from 'react-spinners';
import Cookies from 'js-cookie'; // Import js-cookie

const AuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const dispatch = useDispatch();
  const { decryptedData, status, error } = useSelector((state) => state.auth);
  const userId = Cookies.get('userId'); // Get the userId from cookies

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const encryptedData = query.get('DT');
    if (encryptedData) {
      dispatch(decryptData(encryptedData));
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchCustomerDetails(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      if (decryptedData && typeof decryptedData === 'object') {
        navigate('/home'); // Redirect to the home page if decryption is successful
      } else {
        navigate('*'); // Redirect to an error page if decryption fails or data is invalid
      }
    } else if (status === 'failed') {
      navigate('*'); // Redirect to an error page if the API call fails
    }
  }, [status, decryptedData, navigate]);

  return (
    <div>
      {status === 'loading' && (
        <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
          {/* HashLoader component from react-spinners */}
          <HashLoader color={"#B08E54"} loading={status} size={50} />
        </div>
      )}
    </div>
  );
};

export default AuthCallback;
