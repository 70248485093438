import React from "react";
import Event_Searchbar from "./Event_Searchbar";
import { BiSupport, BiWifi } from "react-icons/bi";
import { NavLink } from "react-router-dom";
const EventDetail = () => {
  const eventDetails = [
    {
      id: 1,
      heading: "PLAN A MEETING ",
      tagline: "Hatimi Retreats: Your Ideal Corporate Meeting Venue",
      description: [
        "Escape the boring conventional boardrooms and immerse your corporate gathering in the tranquility of Hatimi Retreats. Our unique setting seamlessly blends business with the serenity of nature, fostering collaboration and innovation amidst the calming embrace of the woods.",
        "Host your conference in our tranquil environment, where the fresh aroma of pine trees and the peaceful sounds of chirping birds provide a natural backdrop for productive and inspiring meetings. Our versatile meeting spaces are equipped with all the necessary amenities, while our team can cater to your specific needs, ensuring a successful and memorable corporate event.",
        // Add more sentences as needed
      ],
      amenities: [
        {
          name: "Wifi",
          icon: "BiWifi",
          description:
            "At Hatimi, we provide high-speed Wi-Fi that reaches you even amidst lush greenery. Your event will run seamlessly, surrounded by natural beauty. ",
        },
        {
          name: "Assistance",
          icon: "BiSupport",
          description:
            "Host flawless events in nature embrace. Leave the planning to us. Our expert team will ensure your event runs smoothly, allowing you to focus on what matters most. ",
        },
        {
          name: "Eco-Friendly",
          icon: "BiSupport",
          description:
            "At Hatimi Retreats, we are committed to eco-friendly practices like farm-to-table dining and minimizing waste. Our thoughtful menus prioritize sustainability and guest satisfaction.  ",
        },
        // Add more amenities as needed
      ],
      imageUrl: "assets/eventrooms.png", // Replace with your image URL
    },
  ];
  const iconMap = {
    BiWifi: BiWifi,
    BiSupport: BiSupport,
  };

  return (
    <div className="container-fluid">
      {/* Text on Image */}
      <div className="row properties_banner h-100vh">
        {/* Heading and Description */}
        <div className="container text-white">
          <div className="row">
            <div className="d-flex justify-content-center align-items-end">
              <h2 className="display-4">Explore Venues</h2>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Event_Searchbar name="event" />
      </div>
      {/* Cards */}
      <div className="container mb-5 pb-5">
        {eventDetails.map((service) => (
          <>
            <div key={service.id} className="row text-white mb-5 pb-5 gap-3">
              <div className="col-md-6">
                <div className="services-content">
                  <div className="d-flex justify-content-start align-items-start mt-4">
                    <img
                      src="/assets/bg/line.svg"
                      className="linehome mx-3 pt-5"
                      alt="image"
                    />
                    <h1 className="text-gold">{service.heading}</h1>
                  </div>
                  <p className="fs-6 services-description mobileresponsive mb-2">
                    {service.tagline}
                  </p>
                  <div className="fs-6 services-description mobileresponsive">
                    {service.description.map((sentence, index) => (
                      <p key={index} className="py-2">
                        {sentence}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <img
                  loading="lazy"
                  className="d-block w-100 rounded px-5 pt-5"
                  src={service.imageUrl}
                  alt={`Service ${service.id} - Image`}
                />
              </div>
            </div>
            <div className="amenities amenities text-white container mx-5 px-5">
              <div className="row">
                {service.amenities.map((amenity, index) => {
                  const IconComponent = iconMap[amenity.icon];
                  return (
                    <div key={index} className="col-md-4">
                      <div className="amenity text-gold text-center">
                        <div className="amenity-icon ">
                          {IconComponent && <IconComponent size={40} />}
                        </div>
                        <h2 className="text-uppercase">{amenity.name}</h2>
                        <p className="text-white fs-7 px-3">
                          {amenity.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ))}
        <div className="gap-3 d-flex justify-content-center align-items-center pt-5 mt-5">
          <button className="eg-btn btn btn-searchbar w-50  ">
            <NavLink className="nav-link" to="/eventrooms">
              View Meeting Halls{" "}
            </NavLink>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
