import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const userId = Cookies.get("userId"); // Get the userId from cookies

  if (!userId) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
