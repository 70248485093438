import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_BASE_URL;

// Define the initial state of the slice
const initialState = {
  blogDetailData: null,
  loading: false,
  error: null,
};

// Create an async thunk for fetching blog data
export const fetchBlogDetailData = createAsyncThunk(
  'blogDetail/fetchBlogDetailData',
  async (blogId, { rejectWithValue }) => {
    try {
      const token = Cookies.get('token');
      if (!token) throw new Error('Authorization token not found');

      const response = await axios.get(`${apiUrl}/v1/blog/get-blog/${blogId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error('No valid data found');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create the slice
const blogDetailSlice = createSlice({
  name: 'blogDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogDetailData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlogDetailData.fulfilled, (state, action) => {
        state.blogDetailData = action.payload;
        state.loading = false;
      })
      .addCase(fetchBlogDetailData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default blogDetailSlice.reducer;
