import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'

const PrivacyPolicy = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        
      };
    
      useEffect(() => {
        // Trigger scroll to top when the component mounts
        scrollToTop();
      }, []);
  return (
  <div className='container'>
  <div className=' text-white mx-5 px-5 py-3 mobileresponsive'>
  <section className="mb-5 mt-3">
    <h1 className='fs-1'>Privacy Policy </h1>
    </section>
      <section className="mb-4">
      <h2>Introduction</h2>
      <p className='fs-7'> Hatimi Retreats, operated by Hatimi Property Maintenance Private Limited, values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website (https://hatimiretreats.com) and use our services. By accessing or using our services, you consent to the practices described in this policy. </p>
    </section>
      <section className="mb-4">
      <h2>Information We Collect </h2>
      <p className='fs-7'> 
      <p>Personal Information </p>
      <p>We may collect personal information that you provide to us, such as:</p>
        <ul>
          <li>Name </li>
          <li>Email address </li>
          <li>Phone number </li>
          <li>Mailing address </li>
          <li>Payment information.</li>
        </ul>
      </p>
    </section>
      <section className="mb-4">
      <h2>Automatically Collected Information </h2>
      <p>When you visit our website, we may automatically collect information about your device and usage, including:</p>
        <ul>
          <li>IP address </li>
          <li>Browser type </li>
          <li>Operating system</li>
          <li>Referring URLs </li>
          <li>Pages visited </li>
          <li>Date and time of visit </li>
        </ul>
    </section>
      <section className="mb-4">
      <h2>Cookies and Tracking Technologies </h2>
      <p className='fs-7'>
      We may use cookies and similar tracking technologies to track the activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our website. 
        </p>
    </section>
      <section className="mb-4">
      <h2>How We Use Your Information </h2>
      <p className='fs-7'> 
      <p>We use the information we collect for various purposes, including:  </p>
        <ul>
          <li>provide, operate, and maintain our services </li>
          <li>To improve, personalize, and expand our services.</li>
          <li>To understand and analyze how you use our website </li>
          <li>To communicate with you, including responding to your comments, questions, and requests</li>
          <li>To process transactions and send related information, including purchase confirmations and invoices </li>
          <li>To send you technical notices, updates, security alerts, and support and administrative messages </li>
          <li>To detect, prevent, and address technical issues </li>
        </ul>
      </p>
    </section>
      <section className="mb-4">
      <h2>Sharing Your Information</h2>
      <p className='fs-7'>
      <p>We may share your information in the following situations:</p>
      <ul>
          <li>With Service Providers: We may share your information with third-party service providers to perform services on our behalf, such as payment processing, data analysis, email delivery, and hosting services</li>
          <li>For Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. </li>
          <li>With Your Consent: We may disclose your personal information for any other purpose with your consent. </li>
          </ul>
      </p>
    </section>
      <section className="mb-4">
      <h2>Data Security </h2>
      <p className='fs-7'> We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, alteration, or disclosure. However, please be aware that no method of transmission over the Internet or method of electronic storage is completely secure, and we cannot guarantee the absolute security of your information.</p>
    </section>
      <section className="mb-4">
      <h2>Your Data Protection Rights </h2>
      <p className='fs-7'> 
      <p>Depending on your location, you may have the following data protection rights:</p>
      <ul>
          <li>Access: You can request access to your personal information. </li>
          <li>Rectification: You can request that we correct or update any inaccuracies in your personal information.</li>
          <li>Erasure: You can request that we delete your personal information. </li>
          <li>Restriction: You can request that we restrict the processing of your personal information.</li>
          <li>Objection: You can object to the processing of your personal information.</li>
          <li>Data Portability: You can request a copy of your personal information in a structured, commonly used, and machine-readable format. </li>
          <li>To exercise these rights, please contact us using the contact information provided below. </li>
        </ul>
     </p>
    </section>
      <section className="mb-4">
      <h2>Changes to This Privacy Policy </h2>
      <p className='fs-7'>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top. You are advised to review this Privacy Policy periodically for any changes. Your continued use of our services after any changes constitutes your acceptance of the new Privacy Policy. </p>
    </section>
      <section className="mb-4">
      <h2>Contact Us </h2>
      <p className='fs-7'> If you have any questions about this Privacy Policy, please contact us at:  <br/> {' '}
      Hatimi property maintenance private limited, Fakhri makan, Fort house, Dr DN Road, Fort, Mumbai, 400001  <br/>
     Email: info@hatimiproperties.com </p>
    </section>
    </div>
  </div>
   
 
  )
}

export default PrivacyPolicy