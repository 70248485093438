import React, { useRef, useEffect } from "react";
import {
  fetchProperties,
  fetchPropertyLocations,
} from "../redux/Property/propertySlice";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
const Destinations = () => {
  // Sample array of objects containing card information
  const dispatch = useDispatch();
  const { properties, location } = useSelector((state) => state.property);
  const { locations, loading, error } = useSelector((state) => state.property);

  useEffect(() => {
    // Dispatch the fetchProperties action with the current location
    dispatch(fetchProperties(location));
    dispatch(fetchPropertyLocations());
  }, [dispatch]);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  };

  useEffect(() => {
    // Trigger scroll to top when the component mounts
    scrollToTop();
  }, []);

  return (
    <>
      <div className="container-fluid w-100 m-0 ">
        {/* Text on Image */}
        {/* <div className=" properties_banner h-100vh "> */}
          {/* Heading and Description */}
          <div className="container text-white">
            <div className="row  mobileresponsive mt-5 pb-3">
              <div className=" d-flex justify-content-center align-items-center ">
           
                <h1 className=" ">Find Your Desired Destination</h1>
                
              </div>
            </div>
          {/* </div> */}
        </div>

        {/* Cards */}
        <div className="container">
          <div className="row mt-5  ">
            <div className="destination d-flex flex-wrap gap-5 mobileresponsive">
              {locations?.data?.map((card, index) => (
                <>
                  <div className="col-md-7">
                    <div className="services-content text-white d-flex flex-column">
                      <div className="d-flex justify-content-start align-items-start mt-4 mobileresponsive">
                        <img
                          src="/assets/bg/line.svg"
                          className="linehome mx-3 pt-4"
                          alt="image"
                        />
                        <h2 className="text-gold fs-1 m-0">
                          {card.city}
                        </h2>
                      </div>
                      <p className="fs-6 services-description mobileresponsive">
                        {card.description}
                      </p>
                      <div className="services-description mobileresponsive">
                        <NavLink
                          to={`/destinations/propertiedetail/${card.city}`}
                          className="btn eg-btn btn"
                        >
                          View More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      src={card.image}
                      className="d-block w-100 rounded my-3 "
                      alt=""
                      style={{
                        height: "250px",
                        width: "250px",
                        objectFit: "cover",
                        transition: 'height 0.3s ease, width 0.3s ease'
                      }}
                      onError={(e) => {
                        e.target.src = "/assets/noimage.png";
                        e.target.alt = "No Image";
                      }}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Destinations;
