// roomDetailSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const fetchRoomDetails = createAsyncThunk(
  "roomDetails/fetchRoomDetails",
  async ({ propertyId, roomId }, { rejectWithValue }) => {
    try {
      const token = Cookies.get("token");
      if (!token) throw new Error("No token available");

      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.get(
        `${apiUrl}/v1/property/room/get-room/${propertyId}/${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const roomDetailSlice = createSlice({
  name: "roomDetails",
  initialState: {
    rooms: [],
    loading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoomDetails.fulfilled, (state, action) => {
        state.rooms = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoomDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default roomDetailSlice.reducer;
