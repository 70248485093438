import React, { useEffect } from 'react';

const ReservationPolicy = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Trigger scroll to top when the component mounts
    scrollToTop();
  }, []);

  return (
    <>
      <div className="container">
        <div className="text-white mx-5 px-5 py-3 mobileresponsive">
          {/* Title */}
          <section className="mb-4 mt-3">
          <h1 className="fs-1">
               Reservation Policy
            </h1>
          </section>

          {/* Reservation Confirmation */}
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">1. Reservation Confirmation:</h2>
            <p className="fs-6">
              A confirmation email will be sent upon successful booking, including reservation details.
            </p>
          </section>

          {/* Payment */}
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">2. Payment:</h2>
            <p className="fs-6">
              100% of the total payment is required at the time of booking. Accepted payment methods include credit cards, debit cards, net banking, and UPI.
            </p>
          </section>

          {/* Check-In and Check-Out */}
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">3. Check-In and Check-Out:</h2>
            <ul className="fs-6">
              <li>Check-in time is from 12:00 PM.</li>
              <li>Check-out time is until 10:00 AM.</li>
              <li>Early check-in and late check-out are subject to availability and will incur additional charges to be settled upon check-out.</li>
            </ul>
          </section>

          {/* Group Bookings */}
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">4. Group Bookings:</h2>
            <p className="fs-6">
              Special terms and conditions apply for group bookings (more than 5 rooms). Please contact our reservations team for group booking inquiries and arrangements.
            </p>
            <ul className="fs-6">
              <li>
                <strong>Payment:</strong> <span className="text-warning">Non-refundable 50% of the total payment</span> is required at the time of booking. Accepted payment methods include credit cards, debit cards, net banking, and UPI.
              </li>
              <li>Check-in time is from 12:00 PM.</li>
              <li>Check-out time is until 10:00 AM.</li>
              <li>Early check-in and late check-out are subject to availability and will incur additional charges.</li>
            </ul>
          </section>

          {/* Special Requests */}
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">5. Special Requests:</h2>
            <p className="fs-6">
              Special requests are subject to availability and cannot be guaranteed. Please inquire about any special requirements at the time of booking.
            </p>
          </section>

          {/* Rates and Taxes */}
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">6. Rates and Taxes:</h2>
            <p className="fs-6">
              Rates are based on double occupancy unless specified otherwise. Additional charges will be applied for extra guests/persons/beds.
            </p>
          </section>

          {/* Child Policy */}
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">7. Child Policy:</h2>
            <p className="fs-6">
              Children under 5 years stay free when sharing existing bedding with parents.
            </p>
          </section>

          {/* Contact Information */}
          <section className="mb-4">
            <h2 className="fs-5 fw-bold">8. Contact Information:</h2>
            <p className="fs-6">
              For any questions or assistance, please contact our reservations team at +91 75063 05353 or reservations@hatimiretreats.com.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default ReservationPolicy;
