
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeSelectedRoomUid, updateSelectedRoomUid ,decrementSelectedRoomUid,setExtraBedPrice} from "../../redux/room/roomSlice";
import { IoClose } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { FaBed } from "react-icons/fa";
import Cookies from 'js-cookie';
import extraServices, { fetchExtraServices, setServices } from '../../redux/ExtraServices/ExtraServices'
import { useParams } from "react-router-dom";

const CartInfo = () => {
  const selectedRooms = useSelector((state) => state.room.selectedRoomUid);
  const { propertyId } = useParams();
  const count = useSelector((state) => state.room.cartLength);
  const dispatch = useDispatch();
  const [bookingData, setBookingData] = useState(null);
  const { services, loading, error,userSelectedServices } = useSelector((state) => state.ExtraServices);
  const [beds,setBeds] = useState(userSelectedServices.length > 0 ? userSelectedServices[0]?.service_count: 0);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [defaultPrice,setDefaultPrice]=useState(0);
  useEffect(() => {
    const storedData = Cookies.get("searchData");
    if (storedData) {
      const searchData = JSON.parse(storedData);
      const price=services?.find((s,i)=>s.property_uid === propertyId)?.cost;
      setDefaultPrice(Number(price));
      setBookingData(searchData);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchExtraServices());
  }, [dispatch]);

  const handleRemoveClick = (roomId,count) => {
  dispatch(removeSelectedRoomUid({roomId,count}));
  };

  const decrementRoom = (id,count) => {
    if(count!==1){
      dispatch(decrementSelectedRoomUid({id,count,name:"room"}))
    }else{
      toast.error("Not Allowed", {
        style: {
          background: '#1e2526', // Set background color to pink
          color:"white"
        },
        progressStyle: {
          background: '#b69b6c', // Set progress bar color to gold
        },
        closeButton: <IoClose color="white" />, // Set close button to white
        closeOnClick: true, })
    }
  
  };

  const incrementRoom = (id, count) => {
    const room = selectedRooms.find((r) => r.roomId === id);
  
    // Check if the room count exceeds the available rooms
    if (count + 1 <= room.availableRooms) {
      dispatch(updateSelectedRoomUid({ id, count ,name:"room"}));
    } else {
      toast.error("No more available rooms", {
        style: {
          background: '#1e2526',
          color: "white"
        },
        progressStyle: {
          background: '#b69b6c',
        },
        closeButton: <IoClose color="white" />,
        closeOnClick: true,
      });
    }
  };
  
  const handleGuest = () => {
    if (bookingData && bookingData.adults ) {
      return bookingData.adults ;
    } else {
      return 0; // Default value if the data is not available or not in the expected format
    }
  };
  const handleGuestcount = (roomId) =>{
    const room = selectedRooms.find((r) => r.roomId === roomId);
    return room.max_base_guest_occupancy ? room.max_base_guest_occupancy + room.initialBedCount : room.base_guest_occupancy+room.initialBedCount
  };

  const incrementBed=(id,count)=>{
    const room = selectedRooms.find((r) => r.roomId === id);
    const ss=services?.find((s,i)=>s.property_uid === propertyId);
    //console.log(room);
    if(count < (room.max_base_extra_bed ? room.max_base_extra_bed : room.base_extra_bed)){
      //console.log("room",room,ss);
      const extra_ss={
        "service_name":ss?.name,
        "service_price":Number(defaultPrice),
        "service_count":count,
        "service_days":1,
        "single_service_price":Number(defaultPrice)
      }
      
      dispatch(updateSelectedRoomUid({id,count,name:"bed",extra_ss}))
    }else{
      toast.error("No more extra beds", {
        style: {
          background: '#1e2526',
          color: "white"
        },
        progressStyle: {
          background: '#b69b6c',
        },
        closeButton: <IoClose color="white" />,
        closeOnClick: true,
      });
    }  
  };
  const decrementBed=(id,count)=>{
    //const room = selectedRooms.find((r) => r.roomId === id);
    const ss=services?.find((s,i)=>s.property_uid === propertyId);
    console.log(id,count);
    if(count!==0){
      const extra_ss={
        "service_name":ss?.name,
        "service_price":Number(defaultPrice),
        "service_count":count,
        "service_days":1,
        "single_service_price":Number(defaultPrice)
      }
      dispatch(decrementSelectedRoomUid({id,count,name:"bed",extra_ss}));
    }else{
      toast.error("Not Allowed", {
        style: {
          background: '#1e2526', // Set background color to pink
          color:"white"
        },
        progressStyle: {
          background: '#b69b6c', // Set progress bar color to gold
        },
        closeButton: <IoClose color="white" />, // Set close button to white
        closeOnClick: true, })
    }
  };
  const totalGuestCount = selectedRooms.reduce((total, room) => total + Number(handleGuestcount(room.roomId)), 0);
  console.log(userSelectedServices);
  
  
  return (
    <div className="d-flex flex-column gap-3 ">
      <p>{count} Rooms </p>
      {/* <p>Fits {selectedRooms[0]?.max_guest_occupancy} Guests out of {handleGuest} Guests</p> */}
      <p> Accommodated  {totalGuestCount} out of {bookingData ? handleGuest() : 0} Guests</p>
    
      {selectedRooms.map((room, index) => (
        <div key={index} className="cart panel-body text-white rounded w-100 p-3">
         <div className="d-flex justify-content-between align-items-center">
         <h5 className="text-gold m-0 w-75">{room.room_type}</h5>
         <MdDelete className="fs-4 " onClick={() => handleRemoveClick(room.roomId,room.initialCount)}/>
         </div>
          <div className="guest d-flex gap-3 py-4">
            <img src='/assets/bg/guest.svg' className="img-fluid h-50 ps-3" alt="image" />
            <div className="d-flex flex-column">
              
              <p>{room.max_base_guest_occupancy ? room.max_base_guest_occupancy : room.base_guest_occupancy} Guests</p>
              <p className="fs-8 text-gold">Max Capacity {room.max2_guest_occupancy ? room.max2_guest_occupancy : room.max_guest_occupancy}</p>
            </div>
          </div>
          <div className="f">
            <div className="d-flex justify-content-between  align-items-center">
              <h3 className="m-0">{`₹ ${room.room_charge?room.room_charge : room.base_room_charge}`} / N</h3>
              
              <div className="d-flex align-items-center product_detail_increment  text-white rounded">
              <button
                className="btn btn-sm text-white m-0 border-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  decrementRoom(room.roomId,room.initialCount);
                }}
              >
                -
              </button>
              <span className="mx-2">{room.initialCount}</span>
              <button
                className="btn btn-sm text-white m-0 border-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  incrementRoom(room.roomId,room.initialCount);
                 
                }}
              >
                +
              </button>
            </div>
            </div>
           <hr/>
             
            <div className="d-flex justify-content-between align-items-center ">
              <p>{services?.find((s,i)=>s.property_uid === propertyId)?.name}</p>
              <p>₹{room.extra_services.length > 0 ? ((room.extra_services[0]?.service_count *  room.extra_services[0]?.service_price)>0 ? (room.extra_services[0]?.service_count *  room.extra_services[0]?.service_price):defaultPrice ) : defaultPrice}</p>

<div className="d-flex align-items-center justify-content-center product_detail_increment  text-white rounded">
      {/* Decrement Button */}
      <button
        className="btn btn-sm text-white  border-0 "
        onClick={() => {
          decrementBed(room.roomId,room.initialBedCount);
        }}
        disabled={room.initialBedCount === 0} 
      >
        -
      </button>

      {/* Beds Display */}
      <span className="mx-2 ">{room.initialBedCount}</span>

      {/* Increment Button with Tooltip */}
      <div
        className="tooltip-container position-relative"
      >
        <button
          className="btn btn-sm text-white m-0 border-0"
          onClick={() => {
            //console.log(room.base_extra_bed);
            incrementBed(room.roomId,room.initialBedCount);
            
            // if (beds < (room.max_base_extra_bed ? room.max_base_extra_bed : room.base_extra_bed)) {
          
            //   setBeds((prev) => prev + 1);
            // } else {
            //   toast.error("Maximum beds reached.", {
            //     style: {
            //       background: "#1e2526",
            //       color: "white",
            //     },
            //     progressStyle: {
            //       background: "#b69b6c",
            //     },
            //     closeButton: <IoClose color="white" />,
            //     closeOnClick: true,
            //   });
            // }
          }}
          disabled={room.max_base_extra_bed ? room.max_base_extra_bed === room.initialBedCount : room.base_extra_bed  === room.initialBedCount }
        >
          +
        </button>
      </div>
    </div>
      </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CartInfo;
