import React from "react";
import CountUp from 'react-countup';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons

const Gateway = () => {
  return (
    <div className="container">
      <div className="row  text-white ">
        <div className="col-lg-12">
          <span className="mx-5 px-5 mobileresponsive">WELCOME TO HATIMI RETREATS</span>
        <div className='d-flex justify-content-start align-items-start '>
        <img src="/assets/bg/line.svg" className="linehome mx-3 mt-4 pt-3" alt="image" />
        <h1 className="mobileresponsive">Gateway of Serenity</h1>
        </div>
          <div className="paraconternt my-4 mx-5 px-5 mobileresponsive">
            <p>
            We are delighted to welcome you to Hatimi Retreats, where tranquility meets luxury. Nestled in the heart of nature, our exquisite suites across five stunning locations in India offer an unparalleled escape from the everyday hustle and bustle.
            </p>

            <p className="my-3">
            At Hatimi Retreats, we pride ourselves on delivering exceptional hospitality and personalized services tailored to your every need. Whether you are here for a peaceful getaway, a family vacation, or a special celebration, our dedicated team is committed to making your stay truly memorable.
            Explore the beauty of our surroundings, indulge in our premium amenities, and experience the warm hospitality that sets us apart. Your comfort and satisfaction are our top priorities, and we are here to ensure that your stay with us is nothing short of extraordinary.
            </p>
            <p className="my-3">Thank you for choosing Hatimi Retreats. We look forward to creating unforgettable memories with you.</p>
            {/* <button type="submit" className="eg-btn btn btn-searchbar my-3 ">
            know More
          </button> */}
          </div>
        </div>

        {/* <div className="reviews my-5">
           <div className=" d-flex justify-content-center gap-5">
           <div className="">
          <div className="counter-single sibling-2 text-center d-flex flex-column hover-border1 gap-4">
            <div className="counter-icon">
              <img src="assets/bg/peace.svg" style={{width:65,height:65}}alt="" />
            </div>
            <div className="coundown d-flex flex-column">
            <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                <h3 className="odometer" data-odometer-final={28000}><CountUp end={28000} duration ={5} /></h3><i className="bi bi-plus-lg" />
              </div>
              <p>Reviews</p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="counter-single sibling-2 text-center d-flex flex-column hover-border1 gap-4">
            <div className="counter-icon">
       
              <img src="assets/bg/sernity.svg" style={{width:65,height:65}}alt="" />
            </div>
            <div className="coundown d-flex flex-column">
            <div className="d-flex flex-row justify-content-center align-items-center gap-2">
              <h3>4.5</h3>
               
              </div>
              <p>Stellar Ratings</p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="counter-single sibling-2  text-center d-flex flex-column hover-border1 gap-4">
            <div className="counter-icon">
              <img src="assets/bg/luxary.svg" style={{width:65,height:65}}alt="" />
            </div>
            <div className="coundown d-flex flex-column">
            <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                <h3 className="odometer" data-odometer-final={500}><CountUp end={3} duration ={2} /></h3><i className="bi bi-plus-lg" />
              </div>
              <p>Breathtaking Destinations</p>
            </div>
          </div>
        </div>
           </div>
        </div> */}

        <div className="reviews my-3">
           <div className=" " style={{display:"flex",justifyContent:"center",gap:"100px",flexWrap:"wrap"}}>
           <div className="">
          <div className="counter-single sibling-2 text-center d-flex flex-column hover-border1 gap-4">
            <div className="counter-icon">
              <img src="/assets/bg/peace.svg" style={{width:65,height:65}}alt="" />
            </div>
            <div className="coundown d-flex flex-column">
              <h3 >Peace</h3>
            </div>
          </div>
        </div>
        <div className="">
          <div className="counter-single sibling-2 text-center d-flex flex-column hover-border1 gap-4">
            <div className="counter-icon">
       
              <img src="/assets/bg/sernity.svg" style={{width:65,height:65}}alt="" />
            </div>
            <div className="coundown d-flex flex-column">
            <h3>Serenity</h3>
            </div>
          </div>
        </div>
        <div className="">
          <div className="counter-single sibling-2  text-center d-flex flex-column hover-border1 gap-4">
            <div className="counter-icon">
              <img src="/assets/bg/luxary.svg" style={{width:65,height:65}}alt="" />
            </div>
            <div className="coundown d-flex flex-column">
            <h3>Luxury</h3>
            </div>
          </div>
        </div>
           </div>
        </div>
      </div>
    </div>
  );
};

export default Gateway;
