import React from 'react'
import DaypassWorks from '../components/DaypassContent/DaypassWorks'
import DaypassBookingcard from '../components/DaypassContent/DaypassBookingcard'

const Daypass = () => {
  return (
    <div className='container-fluid'>
 <div className=" daypass_banner h-100vh">
        {/* Heading and Description */}
        <div className="container text-white">
          <div className="row">
            <div className="d-flex justify-content-center align-items-end">
              <h1 className="">Explore Day Pass</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <DaypassWorks/>
        <DaypassBookingcard/>
      </div>
    </div>
  )
}

export default Daypass