import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap"; // Import Bootstrap Modal component
import { AiOutlineClose } from "react-icons/ai"; 
import Cookies from "js-cookie";

const Contact = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("token");
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }}
  const { customerDetails } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    name: customerDetails?.fullName || "",
    email: customerDetails?.email || "",
    phone: customerDetails?.mobile_no || "",
    message: "",
    about: "", // Adjusted key to match API payload
  });
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUserTypeChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      about: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/v1/contact/create-contact-us`, formData,config);
      console.log('Response:', response.data);

      // Show success modal
      setShowModal(true);

      // Reset form after submission
      setFormData({
        name: customerDetails?.fullName || "",
        email: customerDetails?.email || "",
        phone: customerDetails?.mobile_no || "",
        message: "",
        about: "",
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Trigger scroll to top when the component mounts
    scrollToTop();
  }, []);

  return (
    <div className="container mt-4 text-white">
      <div className="row p-5 contact mobileresponsive orderform rounded-3 d-flex flex-column justify-content-start">
        <div className="d-flex row">
          <div className="contact_responsive text-start col-6">
            <h2 className="fs-2 my-3">Please tell us a little about you</h2>
            <div className="mb-3">
              <label className="form-label">What would you like to contact us about?</label>
              <div className="d-flex flex-column gap-2">
                <label htmlFor="customer" className="d-flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    id="customer"
                    name="about"
                    value="I'm a Customer"
                    className="cursor-pointer"
                    checked={formData.about === "I'm a Customer"}
                    onChange={handleUserTypeChange}
                  />
                  I'm a Customer
                </label>
                <label htmlFor="cancel" className="d-flex gap-2 cursor-pointer">
                  <input
                    type="radio"
                    id="cancel"
                    name="about"
                    value="I want to Cancel"
                    className="cursor-pointer"
                    checked={formData.about === "I want to Cancel"}
                    onChange={handleUserTypeChange}
                  />
                  I want to Cancel
                </label>
                <label htmlFor="business" className="d-flex gap-2">
                  <input
                    type="radio"
                    id="business"
                    name="about"
                    value="Looking for business"
                    checked={formData.about === "Looking for business"}
                    onChange={handleUserTypeChange}
                  />
                  Looking for business
                </label>
                <label htmlFor="daypass" className="d-flex gap-2">
                  <input
                    type="radio"
                    id="daypass"
                    name="about"
                    value="Looking for Daypass Booking"
                    checked={formData.about === "Looking for Daypass Booking"}
                    onChange={handleUserTypeChange}
                  />
                  Looking for Daypass Booking
                </label>
                <label htmlFor="events" className="d-flex gap-2">
                  <input
                    type="radio"
                    id="events"
                    name="about"
                    value="Looking for Events / weddings"
                    checked={formData.about === "Looking for Events / weddings"}
                    onChange={handleUserTypeChange}
                  />
                  Looking for Events / weddings
                </label>
                <label htmlFor="somethingElse" className="d-flex gap-2">
                  <input
                    type="radio"
                    id="somethingElse"
                    name="about"
                    value="Something else"
                    checked={formData.about === "Something else"}
                    onChange={handleUserTypeChange}
                  />
                  Something else
                </label>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control contact_form"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control contact_form"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <PhoneInput
                  international
                  defaultCountry="US"
                  className="form-control contact_form no-border"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputStyle={{ width: "100%" }}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control contact_form"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-searchbar text-center">
                Submit
              </button>
            </form>
          </div>
          <div className="col-6 my-5 py-3">
            <div className="d-flex flex-wrap mb-2">
              <p className="col-4">Bookings:</p>
              <p className="col-9">
                Reservation@hatimiretreats.com <br /> +91 7506305353
              </p>
            </div>
            <div className="d-flex flex-wrap">
              <p className="col-4">For Maintenance:</p>
              <p className="col-9">
                info@hatimiretreats.com <br /> +91 7506305353
              </p>
            </div>
            <div>
              <h2 className="pt-5">Our Offices</h2>
              <p className="pt-3">Registered Address :</p>
              <p className="fs-7">
                Floor-4, Plot No-896, Fort House, Dadabhai Nawroji Road, Handloom House, Fort Mumbai, Mumbai City MH 400001 IN
              </p>
              <p className="pt-3">Operational Address :</p>
              <p className="fs-7">
                1st Floor, Fakhri Makaan, Bora Bazaar St, Opp Jain Medical, Fort, Mumbai 400001.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered style={{ height: "100%" }}>
        <Modal.Header>
          <Modal.Title style={{ marginTop: "15px" }}>Form Submitted Successfully</Modal.Title>
          <AiOutlineClose
            style={{ color: "white", cursor: "pointer", fontSize: "1.5rem" }}
            onClick={() => setShowModal(false)}
          />
        </Modal.Header>
        <Modal.Body style={{ display: "flex",paddingTop:"40px", }}>
          <p style={{paddingX:"20px"}}>Thank you for reaching out to us! We appreciate your inquiry and are committed to providing a prompt response. Our team is reviewing your message, and we will get back to you shortly with the information you need.</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-round btn-searchbar text-gold px-4" onClick={() => setShowModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contact;
