
import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import axios from "axios";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "react-bootstrap-button-loader";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import {setSelectedRoomUid} from "../../redux/room/roomSlice"
import Preloader from "../Preloader";
import Cookies from "js-cookie"; 


const Billing_Details = (props) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = Cookies.get("token");
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }}
  const { customerDetails, customerStatus, customerError } = useSelector((state) => state.auth);
  const { services, error,userSelectedServices } = useSelector((state) => state.ExtraServices);
  const {orderPrice,extraBedPrice,highestSelectedRoomPrice} = useSelector((state) => state.orderDetail);
  const count = useSelector((state) => state.room.cartLength);
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [coupon, setCoupon] = useState("");
  const [totalCharges,settotalCharges]=useState({
    totalRoomCharges:0,
    totalExtraBedCharges:0,
    tax:{taxAmt:0,sgst:0,cgst:0,igst:0},
    gst_data:{ "cgst_percent": 0, "sgst_percent": 0, "igst_percent": 0 }
  })
  const [totalAmount, setTotalAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [destination, setDestination] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, SetLoading] = useState(false);
  const BookingCheckin = moment(destination?.dateRange?.startDate, "DD MMM YY").format("YYYY-MM-DD");
  const BookingCheckout = moment(destination?.dateRange?.endDate, "DD MMM YY").format("YYYY-MM-DD");
  const numberOfDays = moment(BookingCheckout).diff(moment(BookingCheckin), 'days');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showNoRoomsModal, setShowNoRoomsModal] = useState(false);


  const handleGoBackRooms = () => {
    setShowNoRoomsModal(!showNoRoomsModal);
    dispatch(setSelectedRoomUid({}));
    navigate(-1);};

  const handleGoBack = () => {
    setShowErrorModal(!showErrorModal);
    dispatch(setSelectedRoomUid({}));
    navigate(-1);};

  const toggleModal = () => {
    setShowModal(!showModal);
    dispatch(setSelectedRoomUid({}));
    navigate("/home");
  };

  useEffect(() => {
    const storedData = Cookies.get("searchData");
    if (storedData) {
      const searchData = JSON.parse(storedData);
      setDestination(searchData);
    }

    
  }, []);
useEffect(()=>{
  if(totalAmount > 0 && discount >=0 && destination?.destination?.state && highestSelectedRoomPrice > 0 && extraBedPrice >=0){
    let payAmt=highestSelectedRoomPrice+extraBedPrice;
    let netAmt,taxAmt,cgst,sgst,igst,percent,cgst_percent,sgst_percent,igst_percent;
      if(payAmt >7500){
        percent=18
      }else{
        percent=12
      }
      netAmt=totalAmount-discount;
      taxAmt=netAmt*(percent/100)
      switch(destination.destination.state){
        case "Maharashtra":
          igst=0;
          sgst=taxAmt/2;
          cgst=taxAmt/2;
          cgst_percent=percent/2;
          sgst_percent=percent/2;
          igst_percent=0;
          break;
        case "Rajasthan":
        case "Gujarat":
          igst=taxAmt;
          sgst=0;
          cgst=0;
          cgst_percent=0;
          sgst_percent=0;
          igst_percent=percent;
          break;
        default :
          taxAmt=0;
          igst=0;
          sgst=0;
          cgst=0;
          cgst_percent=0;
          sgst_percent=0;
          igst_percent=0;

      }

      settotalCharges({
        ...totalCharges,
        tax:{
          taxAmt,sgst,cgst,igst
        },
        gst_data:{
          cgst_percent,sgst_percent,igst_percent
        }
      });
      setPayableAmount(netAmt+taxAmt)
  }
  
},[highestSelectedRoomPrice,totalAmount,discount])

const applyCoupon = async () => {
  try {
    if (coupon === "") {
      throw { response: { data: { message: 'Enter your Coupon Code' } } };
    }

    const couponData = {
      "coupon_code": coupon,
      "property_uid":props.property_uid
    };
    
    const response = await axios.post(`${apiUrl}/v1/master/coupon/verify-coupon`, couponData, config);
    const { data } = response.data;
    // Apply discount logic
    if (data.discount_percent) {
      const discount = totalAmount * (data.discount_percent / 100);
      setDiscount(discount);
    } else if (data.discount_flat) {
      setDiscount(data.discount_flat);
    }

  } catch (error) {
    console.log(error);
    setDiscount(0);
    setCoupon("");
    toast.error(error.response.data.message, {
      style: {
        background: '#1e2526',
        color: "white"
      },
      progressStyle: {
        background: '#b69b6c',
      },
      closeButton: <IoClose color="white" />,
      closeOnClick: true,
    });
  }
};

 

  const handlePaymentSuccess = async (response, bookingData) => {
    SetLoading(true)
    const paymentData = {
      ...bookingData, order: response.razorpay_order_id,
      payment_id: response.razorpay_payment_id,
      signature: response.razorpay_signature,
      transaction_id: response.razorpay_order_id,
      mode_of_payment:"online",
    }
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    };
    try {
      const result = await axios.post(`${apiUrl}/v1/booking/create`,paymentData ,config);
      if (result.data.status) {
        SetLoading(false);
        setShowModal(true);
      } else {
        throw new Error('Booking creation failed');
      }
    } catch (error) {
      console.error('Error creating booking:', error);
      setShowErrorModal(true);
    } finally {
      SetLoading(false)
    }
  };


  const handlePay = async () => {
    const formattedCheckin = moment(destination.dateRange.startDate, "DD MMM YY").format("YYYY-MM-DD");
    const formattedCheckout = moment(destination.dateRange.endDate, "DD MMM YY").format("YYYY-MM-DD");
    const bookingData = {
      property_uid: props.property_uid,
      room_info: props.room_info,
      check_in: formattedCheckin,
      check_out: formattedCheckout,
      number_of_rooms: count,
      adults: destination.adults,
      children: destination.children,
      "extra_services_info":[],
      customer_id: customerDetails?._id,
      coupon_code: coupon,
      customer_info: {
        itsID: customerDetails?.itsID,
        name: customerDetails?.fullName,
        mobile_number: customerDetails?.mobile_no,
        email: customerDetails?.email,
        address: customerDetails?.address,
        company_name : props.guestDetails.companyName,
        company_gst : props.guestDetails.gstNumber
      },
      billing_info: {
        total_Cost: totalAmount,    
        discount: discount.toFixed(2),
        sgst: totalCharges?.tax?.sgst.toFixed(2),
        cgst: totalCharges?.tax?.cgst.toFixed(2),
        igst: totalCharges?.tax?.igst.toFixed(2),
        amount_payable:payableAmount.toFixed(2),
      },
      gst_data:totalCharges?.gst_data
    };
    console.log("bookingData",bookingData);
   
    setLoadingButton(true);
    try {
      const orderResponse = await axios.post(`${apiUrl}/v1/booking/create-order`, {
        amount: payableAmount.toFixed(2),
        currency: 'INR',
        payment_status:"",
        isBlock:"false",
        booking_id:"",
        order_id:"",
        property_uid: props.property_uid,
        room_info: props.room_info,
        number_of_rooms: count,
        adults: destination.adults,
        children: destination.children,
        check_in: formattedCheckin,
        check_out: formattedCheckout,
        customer_id: customerDetails?._id,
        coupon_code: coupon,
        customer_info: {
          itsID: customerDetails?.itsID,
          name: customerDetails?.fullName,
          mobile_number: customerDetails?.mobile_no,
          email: customerDetails?.email,
          address: customerDetails?.address,
          company_name : props.guestDetails.companyName,
          company_gst : props.guestDetails.gstNumber
        },
        billing_info: {
          total_Cost: totalAmount,    
          discount: discount.toFixed(2),
          sgst: totalCharges?.tax?.sgst.toFixed(2),
          cgst: totalCharges?.tax?.cgst.toFixed(2),
          igst: totalCharges?.tax?.igst.toFixed(2),
          amount_payable:payableAmount.toFixed(2),
        },
        gst_data:totalCharges?.gst_data
      },config);

      const { status, data } = orderResponse.data

      if (status) {
        const options = {
          "key": process.env.REACT_APP_RAZORPAY_KEY_ID,
          "amount": data.amount.toString(),
          "currency": data.currency,
          "name": 'Hatimi Retreats',
          "description": 'For Hatimi Retreats Room Booking ',
          "order_id": data.id,
          "image": "https://hatimi.s3.amazonaws.com/frontendpropertyImages/hatimigold+(2).svg",
          "handler": function (response) {

            handlePaymentSuccess(response, bookingData);
          },
          "prefill": {
            "name": customerDetails?.fullName || "Test User",
            "email": customerDetails?.email || "test.user@example.com",
            "contact": customerDetails?.mobile_no.substring(3) || "9999999999",
          },
          "notes": {
            "address": 'Hotel Booking Address',
            "name": customerDetails?.fullName || "Test User",
            "email": customerDetails?.email || "test.user@example.com",
            "contact": customerDetails?.mobile_no.substring(3) || "9999999999",
            "order_id": data.id,
          },
          "theme": {
            "color": '#3399cc',
          },
          modal: {
            ondismiss: async function () {
              console.log("User closed the Razorpay modal.");
              const failureData = { order: data.id };
              await axios.post(`${apiUrl}/v1/booking/updatestatus`, failureData, config);
              
            },
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', async function (response) {
          const failureData = { order: response.error.metadata.order_id }
          const result = await axios.post(`${apiUrl}/v1/booking/updatestatus`, failureData,config);

        });
        paymentObject.open();
      }else{
        setShowNoRoomsModal(true);
      }
    } catch (error) {
      console.error('Error making POST request:', error);
      setShowErrorModal(true);
    } finally {
      setLoadingButton(false);
    }

  };
  useEffect(() => {
    if(destination?.destination){
      const BookingCheckin = moment(destination?.dateRange?.startDate, "DD MMM YY").format("YYYY-MM-DD");
      const BookingCheckout = moment(destination?.dateRange?.endDate, "DD MMM YY").format("YYYY-MM-DD");
      const numberOfDays = moment(BookingCheckout).diff(moment(BookingCheckin), 'days');
      const roomCharge = orderPrice * numberOfDays;
      const extraBedCharge=extraBedPrice*numberOfDays;
      let total_amount =roomCharge + extraBedCharge;
      // const state=destination.destination.state;
      setTotalAmount(total_amount);
      settotalCharges({
        ...totalCharges,
        totalRoomCharges:roomCharge,
        totalExtraBedCharges:extraBedCharge,
      });
      
    }
  }, [destination]);
  console.log("orderprize",orderPrice,"extraBedPrice",extraBedPrice,"highestSelectedRoomPrice",highestSelectedRoomPrice);

  return (
    <>
      {loading && (
        <div className="preloader1">
          {/* Preloader content, e.g., a spinner */}
          <Preloader />
        </div>
      )}
      <div className="orderform rounded p-4 mobileresponsive">
        <p className="text-white px-3 pt-1 mobileresponsive">Billing Details</p>
        
        <div className="px-3 pt-3">
        <p className="text-white fs-7 mb-2">Coupon Code</p>
        <div className=" d-flex ">
       
          <input
            type="text"
            className="orderforminput form-control"
            id="couponInput"
            
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
          <button onClick={applyCoupon} className="btn btn-searchbar">
            Apply
          </button>
        </div>
        </div>
        
        <div className="text-white p-3">
          <div className="d-flex justify-content-between mb-3">
            <p>Room price ( {count} Rooms * {numberOfDays} N )</p>
            <p>₹{totalCharges?.totalRoomCharges}</p>
          </div>
          
          <div className="d-flex justify-content-between mb-3">
            <p>Extra Bed</p>
            <p>₹{totalCharges?.totalExtraBedCharges}</p>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <p>Taxes & fees</p>
            <p>₹{totalCharges?.tax?.taxAmt.toFixed(2)}</p>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <p>Coupon discount</p>
            <p>₹{discount.toFixed(2)}</p>
          </div>
          
          <div className="d-flex justify-content-between mb-3">
            <p>Total Amount:</p>
            <p>₹{totalAmount}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between text-white px-3 fs-4">
          <p className="">Payable Amount:</p>
          <p className="">₹{payableAmount.toFixed(2)}</p>
        </div>
        <div className="text-center pt-3">
          <ButtonLoader
            onClick={handlePay}
            loading={loadingButton}
            className="btn btn-searchbar mt-3 w-75 text-center border-0"
          >
            Pay {payableAmount.toFixed(2)}
          </ButtonLoader>

          {showModal && (
            <div className="modal d-flex justify-content-center align-items-center" tabIndex="-1" role="dialog" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
              <div className="modal-dialog rounded " role="document">
                <div className="modal-content p-3">
                  <div className="modal-body fs-7 d-flex justify-content-center align-items-center flex-column">
                    <img src="/assets/bg/success.svg" className="" alt="" />
                    <p className="p-5 mobileresponsive">Congratulations! Your hotel booking has been successfully confirmed. We're thrilled to have you stay with us.</p>
                    <button onClick={toggleModal} className="btn btn-searchbar mt-3 w-75 text-center">
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

{showErrorModal && (
            <div className="modal d-flex justify-content-center align-items-center" tabIndex="-1" role="dialog" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
              <div className="modal-dialog rounded " role="document">
                <div className="modal-content p-3">
                  <div className="modal-body fs-7 d-flex justify-content-center align-items-center flex-column">
                   <div className="mb-2">
                   <MdCancel className="text-danger" style={{fontSize:"3.5rem"}}/>
                    </div>
                   <h3>Booking Failed.</h3>
                    <p className="px-4 py-1 mobileresponsive">Unfortunately, your booking could not be created. Please try again later or contact support.</p>
                    <button onClick={handleGoBack} className="btn btn-searchbar mt-3 w-75 text-center">
                     Back to Rooms
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

{showNoRoomsModal && (
            <div className="modal d-flex justify-content-center align-items-center" tabIndex="-1" role="dialog" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
              <div className="modal-dialog rounded " role="document">
                <div className="modal-content p-3">
                  <div className="modal-body fs-7 d-flex justify-content-center align-items-center flex-column">
                   <div className="mb-2">
                   <MdCancel className="text-danger" style={{fontSize:"3.5rem"}}/>
                    </div>
                   <h3>No Rooms Available.</h3>
                    <p className="px-4 py-1 mobileresponsive">No rooms available for your selected dates. Please try different dates or contact support.</p>
                    <button onClick={handleGoBackRooms} className="btn btn-searchbar mt-3 w-75 text-center">
                     Back to Rooms
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default Billing_Details;
