import React, { useEffect } from 'react'

const TermsAndConditions = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        
      };
    
      useEffect(() => {
        // Trigger scroll to top when the component mounts
        scrollToTop();
      }, []);
  return (
    <div className='container'>
    <div className=' text-white mx-5 px-5 py-3 mobileresponsive'>
    <section className="mb-5 mt-3">
    <h1 className='fs-1'>Terms and Conditions </h1>
    </section>
        <section className="mb-4">
        <h2>Introduction</h2>
        <p className='fs-7'> Welcome to Hatimi Retreats. These Terms and Conditions outline the rules and regulations for the use of our website and services. By accessing our website at (https://hatimiretreats.com), you agree to comply with these terms and conditions. If you disagree with any part of these terms and conditions, please do not use our website or services. </p>
      </section>
        <section className="mb-4">
        <h2>Definitions </h2>
        <p className='fs-7'> 
        Company : Refers to Hatimi Property Maintenance Private Limited, including its affiliate Hatimi Retreats. <br/>
        Service : Refers to the services provided by Hatimi Retreats, including resort management, rentals, and related services. <br/>
        User: Refers to any person who accesses or uses the Service.
        </p>
      </section>
        <section className="mb-4">
        <h2>Use of Service</h2>
        <p>Eligibility</p>
        <p className='fs-7'> You must be at least 18 years old to use our services. By using our services, you represent and warrant that you have the legal capacity to enter into a binding contract. </p>
      </section>
      <section className="mb-4">
        <h2>Account Registration</h2>
        <p className='fs-7'> To access certain features of our services, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and complete. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. </p>
      </section>
        <section className="mb-4">
        <h2>Prohibited Activities </h2>
        <p className='fs-7'>
        <p>Users are prohibited from: </p>
          <ul>
            <li>Violating any local, national, or international law. </li>
            <li>Engaging in fraudulent or deceptive practices.</li>
            <li>Infringing on the rights of others. </li>
            <li>Distributing viruses or other harmful technology.</li>
            <li>Using our services for unauthorized commercial purposes.</li>
          </ul>
          </p>
      </section>
        <section className="mb-4">
        <h2>Payment and Fees </h2>
        <p>Payment Terms</p>
        <p className='fs-7'> All payments for services must be made through the designated payment gateways. By providing payment information, you represent and warrant that the information is accurate and that you are authorized to use the payment method provided. </p>
      </section>
        <section className="mb-4">
        <h2>Refund Policy </h2>
        <p className='fs-7'> Refunds will be provided only in accordance with our refund policy, which is available on our website. </p>
      </section>
        <section className="mb-4">
        <h2>Intellectual Property </h2>
        <p className='fs-7'> All content, trademarks, logos, and intellectual property rights on our website are the property of Hatimi Property Maintenance Private Limited or its licensors. You are granted a limited, non-exclusive, non-transferable license to use the content for personal, non-commercial use. </p>
      </section>
        <section className="mb-4">
        <h2>Privacy Policy</h2>
        <p className='fs-7'> Your use of our services is also governed by our Privacy Policy, which is incorporated by reference into these Terms and Conditions. Please review our Privacy Policy for information on how we collect, use, and disclose personal information. </p>
      </section>
        <section className="mb-4">
        <h2>Limitation of Liability </h2>
        <p className='fs-7'> To the fullest extent permitted by law, Hatimi Property Maintenance Private Limited will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from: </p>
        <ul>
            <li>Your use of or inability to use our services.</li>
            <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
            <li>Any interruption or cessation of transmission to or from our services. </li>
            <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party. </li>
            <li>Any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the services.</li>
          </ul>
      </section>
        <section className="mb-4">
        <h2>Indemnification </h2>
        <p className='fs-7'> You agree to indemnify and hold harmless Hatimi Property Maintenance Private Limited, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of our services or your violation of these Terms and Conditions.  </p>
      </section>
        <section className="mb-4">
        <h2>Changes to Terms and Conditions </h2>
        <p className='fs-7'>We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top. Your continued use of our services after any such changes constitutes your acceptance of the new Terms and Conditions. </p>
      </section>
        <section className="mb-4">
        <h2>Governing Law </h2>
        <p className='fs-7'> These Terms and Conditions are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. </p>
      </section>
        <section className="mb-4">
        <h2>Contact Information </h2>
        <p className='fs-7'> If you have any questions about this Privacy Policy, please contact us at:  <br/> {' '}
        Hatimi property maintenance private limited, Fakhri makan, Fort house, Dr DN Road, Fort, Mumbai, 400001  <br/>
       Email: info@hatimiproperties.com </p>
      </section>
      </div>
    </div>
  )
}

export default TermsAndConditions